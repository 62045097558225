import React from 'react'
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import axios from 'axios'
import https from 'https'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import HubspotStatus from './HubspotStatus'

const agent = new https.Agent({
  rejectUnauthorized: false,
})

const useStyles = makeStyles({
  cell: {
    maxWidth: '65vw',
    overflow: 'auto',
  },
  table: {
    width: '100%',
    margin: '0px auto',
  },
})
export default function TableList(props) {
  const { userID } = props.match.params
  const [rows, setRows] = React.useState([])
  const classes = useStyles()

  const onClickHandler = async (formID) => {
    const resp = await axios.post(
      process.env.NODE_ENV === 'development'
        ? 'http://localhost:2000/list/data'
        : 'https://getbaas.niswey.net/api/list/data',
      {
        httpsAgent: agent,
        data: formID,
      }
    )
    const fileData = Buffer.from(resp.data.data)

    const dateTime = new Date()
      .toISOString()
      .slice(-24)
      .replace(/\D/g, '')
      .slice(0, 14)

    const blob = new Blob([fileData], { type: 'csv' })
    const blobURL = window.URL.createObjectURL(blob)
    const tempLink = document.createElement('a')
    tempLink.style.display = 'none'
    tempLink.href = blobURL
    tempLink.setAttribute('download', 'data' + dateTime + '.csv')
    if (typeof tempLink.download === 'undefined') {
      tempLink.setAttribute('target', '_blank')
    }

    document.body.appendChild(tempLink)
    tempLink.click()
    document.body.removeChild(tempLink)
    window.URL.revokeObjectURL(blobURL)
  }

  const onClickHandlerSheet = async (formID) => {
    let resp = await axios.post(
      process.env.NODE_ENV === 'development'
        ? 'http://localhost:2000/list/googlesheet/data'
        : 'https://getbaas.niswey.net/api/list/googlesheet/data',
      {
        httpsAgent: agent,
        data: { formID, userID },
      }
    )
    const url = `https://docs.google.com/spreadsheets/d/${resp.data.sheetID}/edit#gid=${resp.data.sheetId}`
    const tempLink = document.createElement('a')
    tempLink.style.display = 'none'
    tempLink.href = url
    tempLink.setAttribute('target', '_blank')
    document.body.appendChild(tempLink)
    tempLink.click()
    document.body.removeChild(tempLink)
  }

  React.useEffect(() => {
    const getData = async () => {
      const resp = await axios.post(
        process.env.NODE_ENV === 'development'
          ? 'http://localhost:2000/list'
          : 'https://getbaas.niswey.net/api/list',
        {
          httpsAgent: agent,
          data: userID,
        }
      )
      let data = resp.data.data
      let datalist = data.map(
        (element) =>
          (element = {
            FormID: element.formID,
            Name: element.name,
            SearchType: element.searchType,
            SearchLink: element.url,
            Status: element.status,
            Output: element.output.outputFormat,
          })
      )
      setRows(datalist)
    }
    getData()
  }, [userID])

  return (
    <React.Fragment container>
      <Grid item xs={12}>
        <TableContainer>
          <Table
            aria-label="simple table"
            className="my-3"
            style={{ width: 'auto', tableLayout: 'auto', margin: '0px auto' }}
          >
            <TableHead>
              <TableRow className="bg-secondary">
                <TableCell>FormID</TableCell>
                <TableCell style={{ width: '8%' }}>Name</TableCell>
                <TableCell>Search Type</TableCell>
                <TableCell className={classes.cell}>Search link</TableCell>
                <TableCell>Output</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.FormID}>
                  <TableCell>{row.FormID}</TableCell>
                  <TableCell>{row.Name}</TableCell>
                  <TableCell>{row.SearchType}</TableCell>
                  <TableCell className={classes.cell}>
                    <a href={row.SearchLink} target="_blank" rel="noreferrer">
                      {row.SearchLink}
                    </a>
                  </TableCell>
                  {row.Output === 'CSV' ? (
                    <TableCell>
                      {row.Status === 'Completed' ? (
                        <Button
                          variant="contained"
                          onClick={() => onClickHandler(row.FormID)}
                          style={{
                            padding: '1rem',
                            width: '100%',
                            backgroundColor: '#018786',
                            color: '#FFFFFF',
                          }}
                        >
                          Download as CSV
                        </Button>
                      ) : row.Status === 'Failed' ? (
                        <Button
                          variant="contained"
                          disabled
                          style={{
                            padding: '1rem',
                            width: '100%',
                            backgroundColor: '#B00020',
                            color: '#FFFFFF',
                          }}
                        >
                          Failed
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          disabled
                          style={{
                            padding: '1rem',
                            width: '100%',
                            backgroundColor: '#4DB6AC',
                            color: '#FFFFFF',
                          }}
                        >
                          Crawling
                        </Button>
                      )}
                    </TableCell>
                  ) : null}
                  {row.Output === 'Google sheet' ? (
                    <TableCell>
                      {row.Status === 'Completed' ? (
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => onClickHandlerSheet(row.FormID)}
                        >
                          Show on googlesheet
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          disabled
                          style={{
                            padding: '1rem',
                            width: '100%',
                            backgroundColor: '#4DB6AC',
                            color: '#FFFFFF',
                          }}
                        >
                          Crawling
                        </Button>
                      )}
                    </TableCell>
                  ) : null}
                  {row.Output === 'Display here' ? (
                    <TableCell>
                      {row.Status === 'Completed' ? (
                        <Link
                          to={{
                            pathname: `/Datalist/${row.FormID}`,
                            state: { formID: row.formID },
                          }}
                          style={{ textDecoration: 'none' }}
                        >
                          <Button
                            variant="contained"
                            color="Secondary"
                            style={{ padding: '1rem', width: '100%' }}
                          >
                            Display here
                          </Button>
                        </Link>
                      ) : row.Status === 'Failed' ? (
                        <Button
                          variant="contained"
                          disabled
                          style={{
                            padding: '1rem',
                            width: '100%',
                            backgroundColor: '#B00020',
                            color: '#FFFFFF',
                          }}
                        >
                          Failed
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          disabled
                          style={{
                            padding: '1rem',
                            width: '100%',
                            backgroundColor: '#4DB6AC',
                            color: '#FFFFFF',
                          }}
                        >
                          Crawling
                        </Button>
                      )}
                    </TableCell>
                  ) : null}
                  {row.Output === 'Upload to Hubspot' ? (
                    <TableCell>
                      <HubspotStatus status={row.Status} />
                    </TableCell>
                  ) : null}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </React.Fragment>
  )
}
