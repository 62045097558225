import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


export default function FormDialog(props) {

    const [APIKey,setAPIKey]=React.useState()
    
  const handleClose = () => {
    props.setOpen(false);
  };
  
  const handleKeyChange=(event)=>{
      setAPIKey(event.target.value)
  }

  const submitApiKey=()=>{
    props.setOpen(false);
    props.setAPIKey(()=>APIKey)
       
  }

  return (
    <div>
      <Dialog open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Enter API Key</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter your Hubspot account API key where you want to upload the data
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="apikey"
            label="API Key"
            type="text"
            fullWidth
            onChange={handleKeyChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={submitApiKey} color="primary">
            Set Key
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}