import React from 'react'
import mongoose, { set } from 'mongoose'
import './bootstrap.min.css'
import CheckboxLabel from './CheckboxLabel'
import {
  Button,
  FormGroup,
  Container,
  TextField,
  FormLabel,
  Grid,
} from '@material-ui/core'
import JobTitles from './JobTitles'
import RadioInput from './RadioInput'
import axios from 'axios'
import https from 'https'
import SelectMenu from './SelectMenu'
import NeedDomain from './NeedDomain'
import { Link } from 'react-router-dom'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import SignIn from './SignIn'

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

//import CompanyURL from "./CompanyUrl";

const agent = new https.Agent({
  rejectUnauthorized: false,
})

const ReactForm = () => {
  const [isLoggedIn, setIsLoggedIn] = React.useState(false)
  const [userID, setUserID] = React.useState('')

  const [searchType, setSearchType] = React.useState('Linkedin sales navigator')
  const [name, setName] = React.useState('')
  const [type, setType] = React.useState('Company')
  const [needFunction, setNeedFunctions] = React.useState(false)
  const [needJobTitles, setNeedJobtitles] = React.useState(false)
  const [needPeopleList, setPeopleList] = React.useState(false)
  const [url, setUrl] = React.useState('')
  const [jobtitles, setJobtitles] = React.useState([''])
  const [functions, setFunctions] = React.useState([''])
  const [needEmail, setNeedEmail] = React.useState(false)
  const [needCompanyUrl, setNeedCompanyUrl] = React.useState(false)
  const [checkForHubspot, setCheckForHubspot] = React.useState(false)
  const [checkForHubspotTracking, setCheckForHubspotTracking] =
    React.useState(false)
  const [checkForCMS, setCheckForCMS] = React.useState(false)
  const [needLinkedInUrl, setNeedLinkedinUrl] = React.useState(false)
  const [needCompanySize, setNeedCompanySize] = React.useState(false)
  const [needEmailNoti, setNeedEmailNoti] = React.useState(false)
  const [emailFrequency, setEmailFrequency] = React.useState('Daily')
  const [email, setEmail] = React.useState('')
  const [isError, setIsError] = React.useState(false)
  const [needEmailOutput, setNeedEmailOutput] = React.useState(false)
  const [outputFormat, setOutputFormat] = React.useState('')
  const [open, setOpen] = React.useState(false)
  const [APIKey, setAPIKey] = React.useState('')
  const [sheetcred, setSheetCred] = React.useState('')
  const [filename, setFilename] = React.useState('')
  const [file, setFile] = React.useState('')

  const myForm = React.useRef()

  React.useEffect(() => {
    const LoggedInUserID = localStorage.getItem('userID')
    const accesstoken = localStorage.getItem('token')
    console.log(LoggedInUserID)
    const checkCred = async (userID) => {
      let resp = await axios.post(
        process.env.NODE_ENV === 'development'
          ? 'http://localhost:2000/checkCred'
          : 'https://getbaas.niswey.net/api/checkCred',
        {
          httpsAgent: agent,
          userID,
        }
      )
      if (resp.data.status === 200) {
        if (
          resp.data.data.credentials.googlesheet.token.access_token.length > 0
        )
          setSheetCred(() => resp.data.data.credentials.googlesheet)

        if (resp.data.data.credentials.hubspot.API_KEY.length > 0)
          setAPIKey(() => resp.data.data.credentials.hubspot.API_KEY)
      }
    }

    const auth = async (token) => {
      let resp = await axios.post(
        process.env.NODE_ENV === 'development'
          ? 'http://localhost:2000/auth'
          : 'https://getbaas.niswey.net/api/auth',
        {
          httpsAgent: agent,
          token,
        }
      )
      if (resp.status === 200) {
        setIsLoggedIn(() => true)
        setUserID(() => LoggedInUserID)
        checkCred(LoggedInUserID)
      } else {
        setIsLoggedIn(() => false)
      }
    }

    if (LoggedInUserID) {
      auth(accesstoken)
    }
  }, [userID])

  const handleFileUpload = (e) => {
    if (!e.target.files) {
      return
    }
    const file = e.target.files[0]
    setFile(file)
    const { name } = file
    setFilename(name)


  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }
  const handleLogout = () => {
    localStorage.clear()
    setIsLoggedIn(() => false)
    setUserID(() => '')
  }

  const handleUrlChange = (e) => {
    setUrl(e.target.value)
  }
  const handleEmailChange = (e) => {
    setEmail(e.target.value)
  }

  const handleNameChange = (e) => {
    setName(e.target.value)
  }
  const handleClick = async (event) => {
    event.preventDefault()
    myForm.current.reset()
    if (searchType === 'Linkedin sales navigator') {
      let url = document.getElementById('urlString').value
      if (type === 'People' && url !== '') {
        let ismatch = url.match(/people/)
        if (ismatch === null) {
          setIsError(true)
          return
        } else {
          setIsError(false)
          setOpen(true)
        }
      } else {
        let ismatch = url.match(/company/)
        if (ismatch === null) {
          setIsError(true)
          return
        } else {
          setIsError(false)
          setOpen(true)
        }
      }

      let object = {
        formID: new mongoose.Types.ObjectId(),
        userID,
        searchType: searchType,
        type: type,
        name: name,
        url: url,
        file,
        status: 'Crawling',
        people: {
          needPeopleList: needPeopleList,
          functions: functions,
          jobTitles: jobtitles,
          email: needEmail,
        },
        company: {
          needCompanyUrl: needCompanyUrl,
          needEmails: needEmail,
          checkHubspot: checkForHubspot,
          checkForHubspotTracking,
          checkForCMS: checkForCMS,
          needLinkedInUrl: needLinkedInUrl,
          needCompanySize: needCompanySize,
        },
        output: {
          needEmailOutput: needEmailOutput,
          outputFormat: outputFormat,
        },
        needEmailNoti: {
          needEmailNoti: needEmailNoti,
          email: email,
          emailFrequency: emailFrequency,
        },
      }

      try {
        await axios.post(
          process.env.NODE_ENV === 'development'
            ? 'http://localhost:2000/getbaas/api'
            : 'https://getbaas.niswey.net/api/getbaas/api',
          {
            httpsAgent: agent,
            data: object,
            APIKey,
          }
        )
      } catch (err) {
        console.log(err)
      }
    }

    // csv upload

    let formData = new FormData()

    formData.append('file', file)
    // let data = {
    //   formID: new mongoose.Types.ObjectId(),
    //   userID: userID,
    //   searchType: 'CSV Upload'
    // }
    formData.append('formID', new mongoose.Types.ObjectId())
    formData.append('userID', userID)
    formData.append('searchType', 'CSV Upload')
    formData.append('name', name)
    formData.append('emailNoti', needEmailNoti)
    formData.append('email', email)

    axios.post(process.env.NODE_ENV === 'development'
      ? 'http://localhost:2000/getbaas/api'
      : 'https://getbaas.niswey.net/api/getbaas/api', formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    }).then(data => {
      setOpen(true)
      console.log(data)
    }).catch(err =>
      console.log(err))



  }
  return (
    <Container maxWidth="sm">
      <React.Fragment>
        {!isError && (
          <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="success">
              Crawling process started!
            </Alert>
          </Snackbar>
        )}
        {isError && (
          <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="error">
              Something went wrong!
            </Alert>
          </Snackbar>
        )}
      </React.Fragment>
      {isLoggedIn && (
        <form className="m-5" ref={myForm} onSubmit={handleClick}>
          <FormGroup>
            <TextField
              required
              label="Enter Your Name"
              fullWidth
              value={name}
              onChange={handleNameChange}
              variant="outlined"
            />

            <SelectMenu
              default={searchType}
              for="search"
              setState={setSearchType}
            />
          </FormGroup>
          {searchType === 'Linkedin sales navigator' && (
            <React.Fragment>
              <FormGroup className="my-4">
                {searchType === 'Linkedin sales navigator' && (
                  <TextField
                    id="urlString"
                    error={isError}
                    placeholder="LinkedIn URL"
                    fullWidth
                    value={url}
                    helperText={
                      isError ? 'search type and url mismatched' : null
                    }
                    onChange={handleUrlChange}
                    variant="outlined"
                  />
                )}
              </FormGroup>
              <FormGroup>
                <FormLabel>URL Type</FormLabel>
                <RadioInput
                  setPeopleList={setPeopleList}
                  setType={setType}
                  type={type}
                />
              </FormGroup>
              <FormGroup>
                {type === 'Company' ? (
                  <FormGroup>
                    <CheckboxLabel
                      setState={setNeedCompanyUrl}
                      checkForCMS={checkForCMS}
                      checkForHubspot={checkForHubspot}
                      checkForHubspotTracking={checkForHubspotTracking}
                      needEmail={needEmail}
                      label="Need domain"
                    />
                    <NeedDomain
                      setCheckForHubspot={setCheckForHubspot}
                      setNeedCompanyUrl={setNeedCompanyUrl}
                      setNeedLinkedinUrl={setNeedLinkedinUrl}
                      setPeopleList={setPeopleList}
                      setNeedEmail={setNeedEmail}
                      setCheckForCMS={setCheckForCMS}
                      setCheckForHubspotTracking={setCheckForHubspotTracking}
                      setNeedCompanySize={setNeedCompanySize}
                      needLinkedInUrl={needLinkedInUrl}
                    />

                    <CheckboxLabel
                      setState={setPeopleList}
                      needEmail={needEmail}
                      label="Need People List?"
                    />
                  </FormGroup>
                ) : null}
                {needPeopleList && (
                  <FormGroup className="mx-3">
                    <CheckboxLabel
                      setState={setNeedJobtitles}
                      label="Job Titles"
                      resetState={setJobtitles}
                    />
                    {needJobTitles && (
                      <JobTitles setState={setJobtitles} label="Job Titles" />
                    )}
                    <CheckboxLabel
                      setState={setNeedFunctions}
                      label="Functions"
                      resetState={setFunctions}
                    />
                    {needFunction && (
                      <JobTitles setState={setFunctions} label="Functions" />
                    )}
                    {type === 'People' && (
                      <CheckboxLabel
                        setState={setNeedEmail}
                        label="Need Email Ids"
                      />
                    )}
                  </FormGroup>
                )}
              </FormGroup>


            </React.Fragment>
          )}
          {searchType === 'CSV Upload' &&
            <div>
              <div style={{ margin: '1rem 0' }}>
                <Button
                  component="label"
                  variant="outlined"
                  color='secondary'
                  sx={{ marginRight: '1rem' }}
                >
                  Upload File +
                  <input

                    type="file"
                    accept=".csv"
                    hidden
                    onChange={handleFileUpload}
                  />
                </Button>

              </div>

              <span style={{ margin: '1rem 0' }}>{filename}</span>
            </div>
          }
          {searchType === 'Linkedin sales navigator' && <FormGroup className="my-3">


            <FormLabel>Choose output options</FormLabel>  <CheckboxLabel
              setState={setNeedEmailOutput}
              label="Need Email IDs"
            />
            <CheckboxLabel
              setState={setNeedEmailNoti}
              label="Notify via mail about crawl result"
            />
            {needEmailNoti && (
              <FormGroup>
                <TextField
                  label="Email"
                  id="outlined-size-small"
                  variant="outlined"
                  size="small"
                  onChange={handleEmailChange}
                />
                <SelectMenu
                  default={emailFrequency}
                  for="email-noti"
                  isLoggedIn={isLoggedIn}
                  setState={setEmailFrequency}
                />
              </FormGroup>
            )}
          </FormGroup>}
          <FormGroup className="p-2">
            <SelectMenu
              userID={userID}
              default={outputFormat}
              for="output"
              setState={setOutputFormat}
              setAPIKey={setAPIKey}
              APIKey={APIKey}
              setSheetCred={setSheetCred}
              sheetcred={sheetcred}
            />
          </FormGroup>

          <Grid container justifyContent="center">
            <Grid key={1} sm={4}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={!url.length > 0 && filename === ''}
              >
                Submit
              </Button>
            </Grid>
            <Grid key={2}>
              <Link
                to={{ pathname: `/list/${userID}`, state: { userID } }}
                style={{ textDecoration: 'none' }}
                target="_blank"
              >
                <Button variant="contained" color="secondary">
                  Get List
                </Button>
              </Link>
            </Grid>
          </Grid>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            className="mt-4"
            onClick={handleLogout}
          >
            Log out
          </Button>
        </form >
      )}

      {
        !isLoggedIn && (
          <SignIn setIsLoggedIn={setIsLoggedIn} setUserID={setUserID} />
        )
      }
    </Container >
  )
}

export default ReactForm
