import React from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

export default function CheckboxLabel(props) {
  const [checked, setChecked] = React.useState(false)

  const handleChange = (event) => {
    setChecked(!checked)
    if (
      props.label === 'Check for hubspot tracking code?' ||
      props.label === 'Check for CMS?' ||
      props.label === 'Check if powered by hubspot?' ||
      props.label === 'Need email IDs?'
    ) {
      if (!checked) props.setNeedCompanyUrl(!checked)
    }
    if (props.label === 'Need email IDs?') {
      props.setPeopleList(!checked)
    }
    props.setState(!checked)

    if (
      checked &&
      (props.label === 'Functions' || props.label === 'Job Titles')
    ) {
      props.resetState('')
    }
    //event.preventDefault();
  }

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={
            props.label === 'Need domain'
              ? props.checkForCMS ||
                checked ||
                props.checkForHubspotTracking ||
                props.checkForHubspot ||
                props.needEmail
              : props.label === 'Need People List?'
              ? props.needEmail || checked
              : checked
          }
          onChange={handleChange}
        />
      }
      label={props.label}
    />
  )
}
