/* eslint-disable no-use-before-define */

import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function JobTitles(props) {
  return (
    <Autocomplete
      multiple
      id="checkboxes-tags-demo"
      className='row m-2'
      options={props.label==='Functions'?(fun.map(opt=>opt.name)):jobtitles}
      onChange={(event,value)=>{
        props.setState(value)
      
    }}
      disableCloseOnSelect
      getOptionLabel={(option) => option}
      renderOption={(option, { selected }) => (
        <React.Fragment>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option}
        </React.Fragment>
      )}
      style={{ width: 500 }}
      renderInput={(params) => (
        <TextField {...params}  variant="outlined" label={props.label==='Functions'?"Functions":"Job Tiles"} />
      )}
    />
  );
}



const jobtitles = [
'Chief Executive Officer',
'Managing Director',
'General Manager',
'President',
'Senior Vice President',
'Vice President',
'Chief Operating Officer',
'Chief Financial Officer',
'Head Of Operations',
'Head Of Supply Chain Management'
] 

const fun=[
  {code:1,name:'Accounting'},
  {code:2,name:'Administrative'},
  {code:3,name:'Arts and Design'},
  {code:4,name:'Business Development'},
  {code:5,name:'Community & Social Services'},
  {code:6,name:'Consulting'},
  {code:7,name:'Education'},
  {code:8,name:'Engineering'},
  {code:9,name:'Entrepreneurship'},
  {code:10,name:'Finance'},
  {code:11,name:'Healthcare Services'},
  {code:12,name:'Human Resources'},
  {code:13,name:'Information Technology'},
  {code:14,name:'Legal'},
  {code:15,name:'Marketing'},
  {code:16,name:'Media & Communications'},
  {code:17,name:'Military & Protective Services'},
  {code:18,name:'Operations'},
  {code:19,name:'Product Management'},
  {code:20,name:'Program & Product Management'},
  {code:21,name:'Purchasing'},
  {code:22,name:'Quality Assurance'},
  {code:23,name:'Real Estate'},
  {code:24,name:'Research'},
  {code:25,name:'Sales'},
  {code:26,name:'Support'}
]


