//import ReactForm from './components/ReactForm';
import MainForm from './components/NewForm'
import TableList from './components/TableList.jsx'
import DisplayData from './components/DisplayData'
import Error from './components/Error'
import './App.css'
import { HashRouter as Router, Route, Switch } from 'react-router-dom'

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/" component={MainForm} exact />
        <Route path="/list/:userID" component={TableList} exact />
        <Route path="/Datalist/:FormID" component={DisplayData} exact />
        <Route component={Error} />
      </Switch>
    </Router>
  )
}

export default App
