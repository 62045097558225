import React from 'react'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import axios from 'axios'
import https from 'https'

const agent = new https.Agent({
  rejectUnauthorized: false,
})

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

export default function SignIn(props) {
  const [username, setUserName] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [isError, setIsError] = React.useState(false)
  const classes = useStyles()

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setIsError(false)
  }

  const usernameHandler = (event) => {
    setUserName(event.target.value)
  }

  const passwordHandler = (event) => {
    setPassword(event.target.value)
  }

  const onSubmitHandler = async (event) => {
    event.preventDefault()
    const userObj = {
      username: username,
      password: password,
    }
    console.log(userObj)

    try {
      const resp = await axios.post(
        process.env.NODE_ENV === 'development'
          ? 'http://localhost:2000/login'
          : 'https://getbaas.niswey.net/api/login',
        {
          httpsAgent: agent,
          data: userObj,
        }
      )

      if (resp.data.status === 200) {
        props.setIsLoggedIn(() => true)
        console.log('Logged in')

        const { userID, token } = resp.data.userresp
        props.setUserID(() => userID)
        localStorage.setItem('userID', userID)
        localStorage.setItem('token', token)
      } else setIsError(() => true)
    } catch (err) {
      setIsError(() => true)
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      {isError && (
        <Snackbar open={isError} autoHideDuration={3000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error">
            Invalid username or password
          </Alert>
        </Snackbar>
      )}
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form} onSubmit={onSubmitHandler}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="User Name"
            autoFocus
            value={username}
            onChange={usernameHandler}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            value={password}
            label="Password"
            type="password"
            onChange={passwordHandler}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign In
          </Button>
        </form>
      </div>
    </Container>
  )
}
