import io from 'socket.io-client';
import {Button} from '@material-ui/core';
import React, { useState } from 'react';






const HubspotStatus=(props)=>{


    
    const [status,setStatus]=useState('')
    useState(()=>{
        const socket = io('ws://localhost:2015');
        socket.on('message',(message)=>{
            console.log(message)
            setStatus(()=>message)
            
        })

        if(status==='Completed')
        setStatus(()=>'Completed')
    },[status])


    return(

        <React.Fragment>
            {(props.status==='Completed'||status==='Completed')?<Button variant="contained" disabled style={{backgroundColor: '#00695C', color: '#FFFFFF'}}>Uploaded to hubspot</Button>:
            <Button variant="contained"  disabled style={{ padding: '1rem',width:'100%',backgroundColor: '#4DB6AC',color: '#FFFFFF' }}>{status.length>0?status:'Crawling'}</Button>}
        </React.Fragment>
        
    )
}

export default HubspotStatus