import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import https from 'https'
import axios from 'axios'

const agent = new https.Agent({
  rejectUnauthorized: false,
})

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: '85vh',
  },
})

export default function DisplayData(props) {
  const { FormID } = props.match.params

  const [Tablerows, setTableRows] = React.useState([])
  //const [Tablecolums,setTablecolums]=React.useState([])
  let Tablecolumns = []
  const classes = useStyles()
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)

  React.useEffect(() => {
    const getData = async (FormID) => {
      const resp = await axios.post(
        process.env.NODE_ENV === 'development'
          ? 'http://localhost:2000/Datalist'
          : 'https://getbaas.niswey.net/api/Datalist',
        { httpsAgent: agent, data: FormID }
      )
      let data = resp.data.data
      setTableRows(data)
    }
    getData(FormID)
  }, [FormID])

  //console.log(Tablerows)
  if (Tablerows.length !== 0) {
    Tablecolumns = [...Object.keys(Tablerows[0])]
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {Tablecolumns.length > 0
                ? Tablecolumns.map((column) => (
                    <TableCell
                      key={column}
                      align="left"
                      //style={{ minWidth: column.minWidth }}
                    >
                      {column}
                    </TableCell>
                  ))
                : null}
            </TableRow>
          </TableHead>
          <TableBody>
            {Tablerows.length > 0
              ? Tablerows.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                ).map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.CompanyID}
                    >
                      {Tablecolumns.map((column) => {
                        const value = row[column]
                        return (
                          <TableCell key={column} align="left">
                            {value}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  )
                })
              : null}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={Tablerows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  )
}
