import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import {FormControlLabel,FormControl} from '@material-ui/core';

export default function RadioInput(props) {
   if(props.type==='People') props.setPeopleList(true)
  
    const handleChange = (event) => {
      if(props.type==='People')
          { 
            props.setType('Company')
            props.setPeopleList(false)
          }
        else 
        {
          props.setType('People')
        }

    };
return (
    <FormControl component="fieldset">
      <RadioGroup name="type" value={props.type} onChange={handleChange}>
        <FormControlLabel value="Company" control={<Radio />} label="Company" />
        <FormControlLabel value="People" control={<Radio />} label="People" />
      </RadioGroup>
    </FormControl>
    )
}

