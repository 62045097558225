import React from 'react'
import { FormGroup } from '@material-ui/core'
import CheckboxLabel from './CheckboxLabel'

export default function NeedDomain(props) {
  return (
    <FormGroup id="forCompany">
      <CheckboxLabel
        setState={props.setCheckForHubspot}
        setNeedCompanyUrl={props.setNeedCompanyUrl}
        label="Check if powered by hubspot?"
      />
      <CheckboxLabel
        setState={props.setCheckForHubspotTracking}
        setCheckForHubspotTracking={props.setCheckForHubspotTracking}
        setNeedCompanyUrl={props.setNeedCompanyUrl}
        label="Check for hubspot tracking code?"
      />
      <CheckboxLabel
        setState={props.setCheckForCMS}
        setNeedCompanyUrl={props.setNeedCompanyUrl}
        label="Check for CMS?"
      />
      <CheckboxLabel
        setState={props.setNeedLinkedinUrl}
        label="need LinkedIn URL?"
      />
      <CheckboxLabel
        setState={props.setNeedCompanySize}
        label="need Company Size?"
      />
      <CheckboxLabel
        setState={props.setNeedEmail}
        setNeedCompanyUrl={props.setNeedCompanyUrl}
        setPeopleList={props.setPeopleList}
        label="Need email IDs?"
      />
    </FormGroup>
  )
}
