import React from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

export default function FormDialogSheet(props) {
  const userID = props.userID
  const [client_id, setclient_id] = React.useState('')
  const [client_secret, setclient_secret] = React.useState('')

  const handleClose = () => {
    props.setOpen(false)
  }

  const handleIDChange = (event) => {
    setclient_id(event.target.value)
    //console.log(client_id)
  }
  const handleSecretChange = (event) => {
    setclient_secret(event.target.value)
  }

  const submitApiKey = async () => {
    props.setOpen(false)
    const url = `${
      process.env.NODE_ENV === 'development'
        ? 'http://localhost:2000'
        : 'https://getbaas.niswey.net/api'
    }/list/googlesheet?userID=${userID}&client_id=${client_id}&client_secret=${client_secret}`
    // `https://docs.google.com/spreadsheets/d/${resp.data.SheetID}/edit#gid=${resp.data.sheetId}`
    const tempLink = document.createElement('a')
    tempLink.style.display = 'none'
    tempLink.href = url
    tempLink.setAttribute('target', '_blank')
    document.body.appendChild(tempLink)
    tempLink.click()

    document.body.removeChild(tempLink)
  }

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Enter Your service account credentials
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter these required credentials to upload crawled data to your
            google sheet
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Client ID"
            type="text"
            fullWidth
            onChange={handleIDChange}
          />
          <TextField
            autoFocus
            margin="dense"
            label="Client Secret"
            type="text"
            fullWidth
            onChange={handleSecretChange}
          />
          <DialogContentText>
            Your redirect uri must be "https://getbaas.com/new/gauth" in order
            to authenticate
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={submitApiKey} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
