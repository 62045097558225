import React from "react";
import { InputLabel, MenuItem, Select, FormGroup } from "@material-ui/core";
import FormDialog from "./FormDialog";
import FormDialogSheet from "./FormDialogSheet";

export default function SelectMenu(props) {
  let rows = [];
  const emaiFrequency = ["Daily", "Weekly", "After final output"];
  const outputFormat = [
    "CSV",
    "Google sheet",
    "Display here",
    "Upload to Hubspot",
  ];
  const searchList = [
    "Linkedin sales navigator",
    "BuiltWith list",
    "HubSpot agency listing page",
    "CSV Upload",
  ];
  const [output, setOutput] = React.useState(props.default);
  const [open, setOpen] = React.useState(false);
  const [opensheet, setOpenSheet] = React.useState(false);
  //const [cms,setCMS]=React.useState('');

  if (props.for === "output") {
    outputFormat.forEach((tool) => {
      rows.push(
        <MenuItem required value={tool}>
          {tool}
        </MenuItem>
      );
    });
    props.setState(output);
  } else if (props.for === "email-noti") {
    emaiFrequency.forEach((element) => {
      rows.push(<MenuItem value={element}>{element}</MenuItem>);
    });
    props.setState(output);
  }

  // else if(props.for==='cmsState'){
  //   // props.setState({checkForHubspot:props.state.checkForHubspot,
  //   //   checkForCMS:props.state.checkForCMS,
  //   //   CmsType:output,
  //   // needLinkedInUrl:props.state.needLinkedInUrl
  //   // })

  //   cmsList.forEach(val=>{
  //       rows.push(<MenuItem value={val}>{val}</MenuItem>)
  //   })
  //   props.setState(output)

  // }
  else if (props.for === "search") {
    searchList.forEach((list) => {
      rows.push(<MenuItem value={list}>{list}</MenuItem>);
    });
    props.setState(output);
  }

  const handleChange = (event) => {
    if (event.target.value === "Upload to Hubspot") {
      console.log(props.APIKey);
      if (props.APIKey === "") setOpen(() => true);
    } else if (event.target.value === "Google sheet") {
      if (props.sheetcred.length === 0) {
        setOpenSheet(() => true);
      }
    }

    setOutput(event.target.value);
  };

  return (
    <FormGroup>
      {props.for === "output" ? (
        <InputLabel id="output">Choose output format</InputLabel>
      ) : null}
      {open && (
        <FormDialog
          userID={props.userID}
          open={open}
          setOpen={setOpen}
          setAPIKey={props.setAPIKey}
        />
      )}
      {opensheet && (
        <FormDialogSheet
          userID={props.userID}
          open={opensheet}
          setOpen={setOpenSheet}
          setSheetCred={props.setSheetCred}
        ></FormDialogSheet>
      )}
      <Select
        required
        labelId="output"
        value={output}
        onChange={handleChange}
        className="p-2"
      >
        {rows}
      </Select>
    </FormGroup>
  );
}
